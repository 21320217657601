import { render } from 'preact';
import styled from 'styled-components';
import Landing from './pages/landing';
import Card from './pages/card';
import { useEffect, useState } from 'preact/hooks';
import data from './assets/data.json';
import Slider from './pages/slider';

function App() {
  const { landingTimeout } = data.config;
  const [isLandingVisible, setIsLandingVisible] = useState(true);
  const [isCardVisible, setIsCardVisible] = useState(false);

  useEffect(() => {
    if (isLandingVisible) {
      setTimeout(() => {
        setIsLandingVisible(false);
      }, landingTimeout)
    }
  }, [isLandingVisible]);

  const handleClick = () => {
    if (isLandingVisible) {
      setIsLandingVisible(false);
    }
  };

  return (
    <Container onClick={handleClick}>
      <Landing isVisible={isLandingVisible} />
      <Slider setIsCardVisible={setIsCardVisible} />
      <Card isVisible={isCardVisible} setIsCardVisible={setIsCardVisible} />
    </Container>
  )
}

const Container = styled.section``;

render(<App />, document.body);
