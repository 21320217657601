import data from '../assets/data.json';
import styled from 'styled-components';
import { Br, Text } from '../components/text';
import Copyright from '../components/copyright';
import Link from '../components/link';
import { useEffect, useState } from 'preact/hooks';

export default function Card(props) {
  const { backgroundColor, textColor, title, links, copyright } = data.card;
  const { isVisible, setIsCardVisible } = props;
  const [zIndex, setZIndex] = useState(-1);

  useEffect(() => {
    if (isVisible) {
      setZIndex(40);
    }
  }, [isVisible])

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  const handleWrapperClick = () => {
    setIsCardVisible(false);
    setTimeout(() => setZIndex(-1), 350);
  }

  return (
    <Wrapper backgroundColor={backgroundColor} textColor={textColor} isVisible={isVisible} zIndex={zIndex} onClick={handleWrapperClick}>
      <Container>
        <Title>{ title }</Title>
        <LinkContainer>
          {links.map(({ label, text, url}) => <Link label={label} text={text} url={url} onClick={handleLinkClick} />)}
        </LinkContainer>
      </Container>
      <Copyright copyright={copyright} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: ${({ textColor }) => textColor};
  // display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ isVisible }) => (isVisible ? '100%' : '0%')};
  transition: opacity .3s ease-out;
  cursor: context-menu;
`;

const Container = styled.div`
  position: absolute;
  top: 0.1rem;
  left: 0.4rem;
  
  @media screen and (max-width: 784px) {
    left: 1rem;
    top: 1rem;
    padding-right: 7rem;
  }
`;

const Title = styled.h1`
  ${Text}
`;

const LinkContainer = styled.div`
  ${Br}
`;