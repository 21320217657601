import styled, { css } from 'styled-components';
import SlideImage from './slide-image';
import { Text } from './text';
import images from '../assets/images';
import { useState } from 'preact/hooks';

export default function Slide(props) {
  const {
    backgroundColor = '#000000',
    backgroundImage,
    textColor = '#FFFFFF',
    title = 'Charlie Noon',
    subtitle = '',
    mobileSubtitlePaddingRight = 114,
    image,
    isHidden,
    setIsCardVisible,
  } = props;
  const hasImage = !!image;
  const [timeoutId, setTimeoutId] = useState(undefined);

  const handleTitleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsCardVisible(true)
  };

  const handleTitleMouseEnter = () => {
    setTimeoutId(setTimeout(() => {
      setIsCardVisible(true);
    }, 2000));
  }

  const handleTitleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      textColor={textColor}
      isHidden={isHidden}
    >
      {title && <Title onClick={handleTitleClick} onMouseEnter={handleTitleMouseEnter} onMouseLeave={handleTitleMouseLeave}>{title}</Title>}
      {subtitle && <Subtitle mobileSubtitlePaddingRight={mobileSubtitlePaddingRight}>{subtitle}</Subtitle>}
      {hasImage && <SlideImage isVisible={!isHidden} {...image} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  color: ${({ textColor }) => textColor};
  z-index: 10;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  background: ${(props) =>
    props.backgroundImage
      ? `url(${images.get(
          props.backgroundImage
        )}) no-repeat center center fixed`
      : props.backgroundColor};
  background-size: cover;
`;

const Title = styled.h1`
  ${Text}
  position: absolute;
  top: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;  
  cursor: context-menu;
  z-index: 30;
`;

const Subtitle = styled.h2`
  ${Text}
  position: absolute;
  bottom: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  z-index: 30;

  @media screen and (max-width: 784px) {
    bottom: 0.8rem;
    padding-left: 1rem;
    padding-right: ${({ mobileSubtitlePaddingRight }) => `${mobileSubtitlePaddingRight / 10}rem`};
  }
`;
