{
  "config": {
    "landingTimeout": 5000
  },
  "landing": {
    "backgroundColor": "#000000",
    "textColor": "#FFFFFF",
    "title": "Paris-based creative studio working in the fields of fashion, art and culture at large."
  },
  "card": {
    "backgroundColor": "rgba(255, 255, 255, 0.8)",
    "textColor": "#000000",
    "title": "Paris-based creative studio working in the fields of fashion, art and culture at large.",
    "copyright": "All rights reserved © CJ NOON SRL {currentYear}",
    "links": [
      {
        "label": "Enquiries",
        "text": "hi@charlienoon.com",
        "url": "mailto:hi@charlienoon.com"
      },
      {
        "label": "Instagram",
        "text": "@charlie__noon",
        "url": "https://www.instagram.com/charlie__noon"
      }
    ]
  },
  "slides": [
    {
      "backgroundColor": "#FFFFFF",
      "textColor": "#000000",
      "title": "Charlie Noon",
      "subtitle": "arcin sagdic, Sichtweite. newspaper distributed to worldwide homeless charities",
      "image": {
        "key": "Sichtweite",
        "alt": "",
        "width": 574,
        "height": 823,
        "desktopPadding": 79
      }
    },
    {
      "backgroundColor": "#000000",
      "textColor": "#FFFFFF",
      "title": "Charlie Noon",
      "subtitle": "suffo moncloa, walk to the moon. book design",
      "image": {
        "key": "WalkToTheMoon",
        "alt": "",
        "width": 1045,
        "height": 746,
        "desktopPadding": 118
      }
    },
    {
      "backgroundColor": "#FFFFFF",
      "textColor": "#000000",
      "title": "Charlie Noon",
      "subtitle": "amiri x wes lang. design direction",
      "mobileSubtitlePaddingRight": 0,
      "image": {
        "key": "AmiriWesLang",
        "alt": "",
        "width": 574,
        "height": 752,
        "desktopPadding": 115
      }
    },
    {
      "backgroundImage": "Apparitions",
      "textColor": "#FFFFFF",
      "title": "Charlie Noon",
      "subtitle": "mm6, apparitions. editorial shot by eddie whelan",
      "mobileSubtitlePaddingRight": 124
    }
  ]
}