import AmiriWesLang from './AmiriWesLang_Cover 1.png';
import Sichtweite from './Screenshot 2022-06-02 at 13.37 2.png';
import WalkToTheMoon from './SuffoMocloa.png';
import Apparitions from './MM6_Apparitions.png';

const images = new Map();
images.set('AmiriWesLang', AmiriWesLang);
images.set('Sichtweite', Sichtweite);
images.set('WalkToTheMoon', WalkToTheMoon);
images.set('Apparitions', Apparitions);

export default images;
