import styled from 'styled-components';
import data from '../assets/data.json';
import { useEffect, useState } from 'preact/hooks';
import { Text, Bottom } from './text';

export default function Copyright({ copyright }) {
  const [text, setText] = useState('');

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setText(copyright.replace('{currentYear}', currentYear));
  }, [copyright]);

  return <Paragraph>{text}</Paragraph>;
}

const Paragraph = styled.p`
  ${Text}
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  
  @media screen and (max-width: 784px) {
    left: 0;
    bottom: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
