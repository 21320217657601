import styled from 'styled-components';
import { Text } from './text';

export default function Link(props) {
  const { label, text, url, onClick } = props;

  return (
    <A href={url} onClick={onClick} target="_blank">
      {label}: {text}
    </A>
  );
}

const A = styled.a`
  ${Text}
  display: block;
  color: inherit;
  text-decoration: none;
`;
