import { css } from 'styled-components';

export const Text = css`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.686rem;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.016rem;
  
  @media screen and (max-width: 784px) {
    font-size: 1.4rem;
    line-height: 1.816rem;
  }
`;

export const Top = css`
  position: absolute;
  top: 0.1rem;
  left: 0.4rem;
`;

export const Bottom = css`
  position: absolute;
  bottom: 0.2rem;
  left: 0.6rem;
  
  @media screen and (max-width: 784px) {
    margin-top 1.816rem;
  }
`;

export const Br = css`
  margin-top: 1.686rem;
  
  @media screen and (max-width: 784px) {
    margin-top 1.816rem;
  }
`;