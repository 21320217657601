import data from '../assets/data.json';
import styled from 'styled-components';
import { Text } from '../components/text';

export default function Landing(props) {
  const { backgroundColor, textColor, title } = data.landing;
  const { isVisible } = props;

  return (
    <Wrapper backgroundColor={backgroundColor} textColor={textColor} isVisible={isVisible}>
      <Title>{ title }</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: ${({ textColor }) => textColor};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  z-index: 40;
`;

const Title = styled.h1`
  ${Text}
  position: absolute;
  top: 0.1rem;
  left: 0.4rem;
  
  @media screen and (max-width: 784px) {
    left: 1rem;
    top: 1rem;
    padding-right: 7rem;
  }
`;