import { useEffect, useState } from 'preact/hooks';
import styled, { css } from 'styled-components';
import Slide from '../components/slide';
import data from '../assets/data.json';

export default function Slider(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideSize = data.slides.length - 1;
  const { setIsCardVisible } = props;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const incrementIndex = () => {
    setCurrentIndex((previousIndex) =>
      previousIndex === slideSize ? 0 : previousIndex + 1
    );
  };

  const decrementIndex = () => {
    setCurrentIndex((previousIndex) =>
      previousIndex === 0 ? slideSize : previousIndex - 1
    );
  };

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 37:
        decrementIndex();
        break;
      case 39:
        incrementIndex();
        break;
    }
  };

  const slides = data.slides.map((slide, index) => (
    <Slide
      backgroundColor={slide.backgroundColor}
      backgroundImage={slide.backgroundImage}
      textColor={slide.textColor}
      title={slide.title}
      subtitle={slide.subtitle}
      mobileSubtitlePaddingRight={slide.mobileSubtitlePaddingRight}
      image={slide.image}
      isHidden={index !== currentIndex}
      setIsCardVisible={setIsCardVisible}
    />
  ));

  return (
    <Container onClick={incrementIndex}>
      {slides}
    </Container>
  );
}

const Container = styled.section`
  cursor: pointer;
`;

const Area = css`
  position: absolute;
  top: 15vh;
  height: 70vh;
  width: 35vw;
  cursor: pointer;
  z-index: 20;
`;
