import styled from 'styled-components';
import images from '../assets/images';
import { useRef, useEffect, useState } from 'preact/hooks';
import throttle from 'lodash/throttle';

export default function SlideImage(props) {
  const {
    key,
    alt,
    width,
    height,
    desktopPadding,
    tabletPadding,
    mobilePadding,
    isVisible
  } = props;
  const wrapper = useRef(null);
  const image = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (isVisible) {
      handleResize();
      const throttleHandler = throttle(handleResize, 200);
      window.addEventListener('resize', throttleHandler);
      return () => window.removeEventListener('resize', throttleHandler);
    }
  }, [isVisible, isMobile]);

  const getWrapperHeight = () => {
    const computedStyle = getComputedStyle(wrapper.current);
    const wrapperHeight = wrapper.current.clientHeight;
    return wrapperHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom);
  };

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const wrapperHeight = getWrapperHeight();
    const wrapperWidth = wrapper.current.offsetWidth;
    const ratio = width / height;
    const imageWidth = wrapperHeight * ratio;

    if (!isMobile && (windowWidth <= 784 || imageWidth > wrapperWidth)) {
      setIsMobile(true);
    } else if (isMobile && (windowWidth > 784 && imageWidth < wrapperWidth)) {
      setIsMobile(false);
    }
  };

  return (
    <ImageWrapper  ref={wrapper} desktopPadding={desktopPadding} imageWidth={width} isMobile={isMobile}>
      <Image
        ref={image}
        ratio={`${width} / ${height}`}
        percentage={(width / height) * 100}
        src={images.get(key)}
        alt={alt}
        imageWidth={width}
        desktopPadding={desktopPadding}
        isMobile={isMobile}
      />
    </ImageWrapper>
  );
}

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  box-sizing: border-box;
  padding-top: ${({ desktopPadding }) => `${desktopPadding / 10}rem`};
  padding-bottom: ${({ desktopPadding }) => `${desktopPadding / 10}rem`};
  padding-left: 1rem;
  padding-right: 1rem;
  
  ${props => props.isMobile && 'padding-top: 0'}
  ${props => props.isMobile && 'padding-bottom: 0'}
`;

const Image = styled.img`
  flex-grow: 1;
  height: 100%;
  // aspect-ratio: ${({ ratio }) => ratio};
  z-index: 20;
  
  ${props => props.isMobile && 'flex-grow: 0;'}
  ${props => props.isMobile && 'height: auto;'}
  ${props => props.isMobile && 'width: 100%;'}
  
  // @supports not (aspect-ratio: ${({ ratio }) => ratio}) {
  //   ::before {
  //     float: left;
  //     padding-top: ${({ percentage }) => percentage}%;
  //     content: '';
  //   }
  //
  //   ::after {
  //     display: block;
  //     content: '';
  //     clear: both;
  //   }
  // }
`;
